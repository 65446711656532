#tsparticles{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
}
@font-face {
  font-family: 'silka';
  src: url('./constants/Silka/regular.ttf');
}

* {
  font-family: 'silka' !important;
}